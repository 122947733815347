@charset "utf-8";

.u-ff--lato{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}

.u-fv{
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
     letter-spacing:3px;
}

.u-tac{
  text-align:center;
}

.u-tal{
  text-align:left;
}

.u-tar{
  text-align:right;
}

.u-vat{
  vertical-align:top;
}

.u-vam{
  vertical-align:middle;
}

.u-vab{
  vertical-align:bottom;
}

@for $i from 10 through 36{
  .u-fz-#{$i}{ font-size: #{$i}px; }
}

.u-fwb{
  font-weight:bold;
}

.u-fwn{
  font-weight:normal;
}

.u-fc--white{
  color:#fff;
}

.u-fc--black{
  color:#000;
}

.u-fc--main-color{
  color:#38457d;
}

.u-fc--accent-color{
  color:#a80000;
}

.u-txtdeco-none{
  text-decoration: none;
}