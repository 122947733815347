@charset "utf-8";

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 35px 40px 35px 40px;
    z-index: 4;
}

.under-page {
    background-position: center top;
    background-repeat: no-repeat;
    background-image: url("../img/common/header_bg.png");

    .header {
        position: relative;
        top: auto;
        left: auto;
        min-height: 250px;
        z-index: 2;
    }
}

.global-nav {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

    .global-nav__title-logo {
        display: inline-block;
    }

    .global-nav__menu {
          -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

        .global-nav__menu__list {
            margin: 0 0 0 30px;

            a {
                color: #000;
                //font-size: 24px;
            }

            .icon-sns{
                //width: 21px;
                vertical-align: sub;
            }
        }

        .global-nav__menu__list--pulldown {
            position: relative;
            margin: 0 0 0 18px;
            padding: 0 13px 0 0;
            cursor: pointer;
            color: #fff;
            font-weight: bold;

            &:after {
                position: absolute;
                right: -1px;
                top: 1px;
                content: "\f107";
                font-weight: 900;
                font-family: 'Font Awesome 5 Free';
            }

            a{
                color: #fff;
            }

            ul {
                display: none;

                .global-nav__menu__list--pulldown__list {
                    position: relative;
                    margin: 6px 0 0 0;

                    a {
                        display: block;
                        position: relative;
                        padding: 0 0 0 18px;
                        color: #fff;
                        font-size: 13px;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1025px) {

    .drawer-hamburger {
        display: none;
    }

    .global-nav__cover--sp {
        display: none;
    }
}

@media screen and (min-width: 1240px){
    .global-nav__menu__list{
        a{
            font-size: 21px;
            letter-spacing: 1.92px;
        }
        .icon-sns{
            width: 30px;
        }
    }
}

@media screen and (max-width: 1240px){
    .global-nav__menu__list{
        .icon-sns{
            width: 21px;
        }
    }
}


@media screen and (max-width: 1024px) {

    .drawer--right.drawer-open .drawer-hamburger {
        right: 0;
        background-color: #FFF;
    }

    .global-nav__cover--pc {
        display: none;
    }

    .global-nav__cover--sp {
        top: 68px;
    }

    .header {
        padding: 14px 15px 13px 15px;
        position: fixed;
        width: 100%;
        background-color: #FFF;
    }

    .global-nav {
        display: block;

        .global-nav__title-logo {
            display: block;
            width: 185px;
            margin: 0 auto;

            img{
                margin-left: -20px;
            }
        }

        .global-nav__cover {
            display: block;
            overflow-y: scroll;
        }

        .global-nav__menu {
            display: block;
            width: 100%;

            .global-nav__menu__list {
                margin: 0;
                a {
                    display: block;
                    position: relative;
                    padding: 15px 30px 15px 20px;
                    color: #fff;
                    border-bottom: 1px solid #606a97;
                    font-weight: bold;

                    &:after {
                        position: absolute;
                        right: 25px;
                        top: 8px;
                        content: "\f105";
                        font-size: 24px;
                        font-weight: 900;
                        font-family: 'Font Awesome 5 Free';
                    }
                }
            }

            .global-nav__menu__list--sns {
                text-align: center;
                display: flex;
                justify-content: center;
                margin: 40px auto 0 auto;


                a{
                    display: block;
                    width: 42px;
                    &:not(:last-child){
                        margin-right: 20px;
                    }
                }
            }

            .global-nav__menu__list--pulldown {
                display: block;
                position: relative;
                margin: 0;
                padding: 20px 30px 20px 20px;
                color: #fff;
                border-bottom: 1px solid #606a97;

                &:after {
                    display: none
                }

                .icon,
                .icon span {
                    display: inline-block;
                    transition: all .4s;
                    box-sizing: border-box;
                }
                .icon {
                    position: relative;
                    width: 10px;
                    height: 10px;
                    float: right;

                    span {

                        &:nth-of-type(1) {
                            position: absolute;
                            top: 12px;
                            left: 5px;
                            width: 9px;
                            height: 1px;
                            background-color: #fff;
                            transform: rotate(0deg);
                            -webkit-transform: rotate(0deg);
                            -moz-transform: rotate(0deg);
                            -ms-transform: rotate(0deg);
                            -o-transform: rotate(0deg);
                        }
                        &:nth-of-type(2) {
                            position: absolute;
                            top: 12px;
                            left: 5px;
                            width: 9px;
                            height: 1px;
                            background-color: #fff;
                            transform: rotate(90deg);
                            -webkit-transform: rotate(90deg);
                            -moz-transform: rotate(90deg);
                            -ms-transform: rotate(90deg);
                            -o-transform: rotate(90deg);
                        }

                        &:nth-of-type(3) {
                            position: absolute;
                            top: 2px;
                            left: -1px;
                            width: 21px;
                            height: 21px;
                            border: 1px solid #fff;
                            border-radius: 50px;
                        }
                    }
                }

                .icon.is-open span:nth-of-type(2) {
                    top: 12px;
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                }

                ul {
                    padding: 4px 0 0 0;

                    .global-nav__menu__list--pulldown__list {
                        margin: 5px 0 0 10px;
                        a {
                            color: #fff;
                            padding: 3px 0 0 0;

                            &:before {
                                display: none
                            }
                        }
                    }
                }
            }
        }
    }
}

