@charset "utf-8";

.full-wide-slider {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 8vw;
    margin-bottom: 50px;
    opacity: 0;
    visibility: hidden;
    transition: all 1.2s;

    &.slick-initialized {
        opacity: 1;
        visibility: visible;
    }
}

.slick-slide {
    position: relative;
    height: 38vw;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden; // background-color: #000;
        // opacity: .3;
        z-index: 1;
    }

    video {
        // pointer-events:none;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        transform: translate(-50%, -50%);
    }

    iframe {
        position: relative;
        pointer-events: none;
    }

    figure {
        position: relative;
        height: 100%;
    }

    .slide-image {
        opacity: 1;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-image: url("../img/top/visual.jpg");
        transition: all .8s ease;

        &.show {
            opacity: 1;
        }
    }

    .image-entity {
        width: 100%;
        height: auto;
        opacity: 0;
    }

    .slide-media {
        animation: slideOut .4s cubic-bezier(0.4, 0.29, 0.01, 1);
    }

    &.slick-active {
        z-index: 1;

        .slide-media {
            animation: slideIn 2.4s cubic-bezier(0.4, 0.29, 0.01, 1);
        }

        .caption {
            // opacity: 1;
            // transform: translateY(0);
            // transition: all .7s cubic-bezier(0.32, 0.34, 0, 1.62) .6s;
        }
    }
}

.caption {
    position: absolute;
    top: 27vh;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1230px;
    padding: 15px;
    margin: 0 auto;
    letter-spacing: .02em;
    opacity: 1;
    z-index: 1; // transition: all .3s ease;
    // transform: translateY(100px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    .svg-wrap {
        width: 100%;
        max-width: 600.484px;
        height: 277.905px;

        #svg-anime01 {
            width: 100%;

            .cls-3 {
                fill: none;
                stroke: #FFF;
                stroke-width: 22;
                stroke-miterlimit: 10;
                stroke-dasharray: 2000;
                stroke-dashoffset: 2000;
            }

            img {
                width: auto;
            }
        }
    }
}

.slick-dots {
    text-align: center;
    padding-top: 15px;

    li {
        display: inline-block;
        vertical-align: top;
        margin: 0 8px;

        button {
            width: 16px;
            height: 16px;
            border: none;
            cursor: pointer;
            border-radius: 50%;
            border: 2px solid #fff;
            box-shadow: 0 0 0 0 transparent;
            vertical-align: middle;
            color: #fff;
            background-color: #fff;
            transition: all .3s ease;
            opacity: .4;

            &:focus {
                outline: none;
            }

            &:hover {
                opacity: 1;
            }
        }

        &.slick-active {
            button {
                box-shadow: 0 0 0 2px #fff;
                opacity: 1;
            }
        }
    }
}

.p-main-visual {
    height: 100%;
    position: relative;

    #svg-anime01 {
        max-width: 100%;
        height: auto;
        -webkit-animation: in 2.1s;
        animation: in 2.1s;
        overflow: hidden;
        fill: none;
        stroke: currentColor;
    }


    #svg-anime01 .cls-3 {
        fill: none;
        stroke: #FFF;
        stroke-width: 22;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 2000;
        stroke-dashoffset: 2000;
    }


    .loading {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -27px 0 0 -123px;
        -webkit-animation: fadeout 3s;
        animation: fadeout 3s;
        opacity: 0;

        #svg-animation {
            position: relative;
            width: 64.891mm !important;
            height: 14.059mm !important;
            overflow: hidden;
        }

        #svg-animation svg {
            opacity: 0;
        }

        #svg-animation .cls-1 {
            fill: #374278;
        }

        #svg-animation .cls-2 {
            fill: #9f1f24;
        }

        #svg-animation path,
        #svg-animation rect {
            position: absolute;
            left: 0;
            top: 0;
            transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
            transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
            transform: rotateX(90deg);
            transform-origin: center top;
            opacity: 0;
        }

        #svg-animation rect.cls-1 {
            transition-delay: 0ms;
        }

        #svg-animation path:nth-child(1) {
            transition-delay: 100ms;
        }

        #svg-animation path:nth-child(2) {
            transition-delay: 150ms;
        }

        #svg-animation rect.cls-2 {
            transition-delay: 200ms;
        }

        #svg-animation path:nth-child(3) {
            transition-delay: 400ms;
        }

        #svg-animation path:nth-child(4) {
            transition-delay: 450ms;
        }

        #svg-animation path:nth-child(5) {
            transition-delay: 500ms;
        }

        #svg-animation path:nth-child(6) {
            transition-delay: 550ms;
        }

        #svg-animation path:nth-child(7) {
            transition-delay: 600ms;
        }

        #svg-animation path:nth-child(8) {
            transition-delay: 650ms;
        }

        #svg-animation path:nth-child(9) {
            transition-delay: 700ms;
        }

        #svg-animation path:nth-child(10) {
            transition-delay: 750ms;
        }

        #svg-animation path:nth-child(11) {
            transition-delay: 800ms;
        }

        #svg-animation path:nth-child(12) {
            transition-delay: 300ms;
        }

        #svg-animation path:nth-child(13) {
            transition-delay: 351ms;
        }

        #svg-animation path:nth-child(14) {
            transition-delay: 390ms;
        }

        #svg-animation path:nth-child(15) {
            transition-delay: 429ms;
        }

        #svg-animation path:nth-child(16) {
            transition-delay: 468ms;
        }

        #svg-animation path:nth-child(17) {
            transition-delay: 507ms;
        }

        #svg-animation path:nth-child(18) {
            transition-delay: 546ms;
        }

        #svg-animation path:nth-child(19) {
            transition-delay: 585ms;
        }

        #svg-animation path:nth-child(20) {
            transition-delay: 624ms;
        }

        #svg-animation path:nth-child(21) {
            transition-delay: 663ms;
        }

        #svg-animation path:nth-child(22) {
            transition-delay: 702ms;
        }

        #svg-animation path:nth-child(23) {
            transition-delay: 741ms;
        }

        #svg-animation path:nth-child(24) {
            transition-delay: 780ms;
        }

        #svg-animation path:nth-child(25) {
            transition-delay: 819ms;
        }

        #svg-animation path:nth-child(26) {
            transition-delay: 39ms;
        }

        #svg-animation path:nth-child(27) {
            transition-delay: 78ms;
        }

        #svg-animation.active path,
        #svg-animation.active rect {
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            transform: rotateX(0deg);
            opacity: 1;
        }
    }

    .p-main-visual__scroll {
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -30px;
        width: 61px;
        height: 70px;
        text-align: center;

        .p-main-visual__scroll__link {
            position: relative;
            display: block;
            color: #fff;
            letter-spacing: 1px;

            &:after {
                position: absolute;
                content: "";
                bottom: -46px;
                left: 30px;
                display: block;
                width: 1px;
                height: 40px;
                background-color: #FFF;
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .slick-slide {
        .slide-image {
            background-image: url("../img/top/visual_sp.jpg");
        }
    }

    .caption {
        top: 34vh;
        padding: 15px 40px;

        .svg-anime01 {
            width: 100%;
            height: auto;

            .image {
            }
        }
    }
}

@keyframes slideIn {
    from {
        filter: blur(15px);
    }
    to {
        filter: blur(0);
    }
}

@keyframes slideOut {
    from {
        filter: blur(0);
    }
    to {
        filter: blur(15px);
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes in {
    0% {
        opacity: 0;
    }

    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.p-top-news {
    margin-top: 250px;
    padding-bottom: 150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    .p-top-news__left {
        width: 25%;
        min-width: 250px;
        padding-right: 5%;

        .c-btnA {
            margin-top: 30px;
            max-width: 170px;
        }
    }

    .p-top-news__right {
        width: 75%;
    }
}

.p-top-about {
    background-image: url("../img/top/about_bg.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;

    .p-top-about__ttl {
        margin-bottom: 70px;
    }

    .p-top-about__unordered {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .p-top-about__unordered__list {
            min-width: 360px;
            border: solid 2px #38457d;
            position: relative;
            margin-bottom: 80px;
            background-clip: content-box;

            .p-top-about__unordered__list__contents {
                text-align: center;
                padding: 45px 5% 67px 5%;

                .p-top-about__unordered__list__ttl {
                    font-size: 26px;
                }

                .p-top-about__unordered__list__border {
                    border-top: 3px solid black;
                }

                .p-top-about__unordered__list__heading {
                    font-size: 64px;
                }

                .p-top-about__unordered__list__des{
                    font-weight: 700;
                }
            }
            .p-top-about__unordered__list__btn {
                margin: 0 auto;
                position: absolute;
                z-index: 2;
                top: 95%;
                left: 0;
                right: 0;
                opacity: 1;
                &:hover ~ .u-slide::before{
                    top: 0;
                }
            }
        }
    }
}

.p-top-business {
    padding-top: 120px;
    padding-bottom: 130px;
    position: relative;
    background-image: url("../img/top/business_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    .p-top-business__unordered {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;

        .p-top-business__unordered__list {
            position: relative;
            width: 50%;
            min-width: 575px;
            margin: 0 auto;

            &:nth-child(1) {
                padding: 0 0 50px 60px;

                .p-top-business__unordered__list__link {
                    top: -30px;
                    left: -55px;
                }
                .p-top-business__unordered__list__text {
                    left: 0;
                    bottom: 8px;
                }
            }

            &:nth-child(2) {
                padding: 100px 60px 0 0;
                .p-top-business__unordered__list__link {
                    top: -540px;
                    left: 130px;
                }
                .p-top-business__unordered__list__text {
                    top: 0;
                    right: 0;
                }
            }

            .p-top-business__unordered__list__text {
                //position: absolute;
                z-index: 2;
                display: inline-block;
                padding: 30px 40px;
                text-transform: uppercase;
                border: solid 2px #000;
                color: #000;

                .p-top-business__unordered__list__text__heading {
                    font-size: 26px;
                }

                .p-top-business__unordered__list__text__ja {
                    font-size: 12px;
                }
            }
        }
    }
}


.p-top-portfolio {
    position: relative;
    height: 100%;
    margin-bottom: 200px;
    padding-top: 150px;
    padding-bottom: 130px;
    position: relative;
    background-image: url("../img/top/portfolio_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    .p-top-portfolio__visual {
        position: absolute;
        bottom: -60px;
        left: 0;
        display: block;
        width: 50%;
    }

    .p-top-portfolio__wrap {
        width: 50%;
        padding-left: 10%;
        margin: 0 0 0 auto;

        .p-top-portfolio__wrap__text {
            margin: 40px 0 60px 0;
            color: #fff;
            width: 420px;
        }
    }
}


.p-top-interview {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        display: block;
        width: 40%;
        height: 320px;
        background-color: $main-color;
    }

    .p-container {
        position: relative;

        .c-btnC {
            position: absolute;
            z-index: 2;
            top: 50px;
            right: 15px;
        }
    }

    .p-top-interview__unordered {
        position: relative;
        z-index: 2;
        margin-top: 90px;
        margin-bottom: 120px;

        .p-top-interview__unordered__list {
            margin-top: 30px;
            padding: 30px;
            border: 1px solid #e1e1e1;
            background-color: #FFF;

            .p-top-interview__unordered__list__link {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;

                .p-top-interview__unordered__list__link__left {
                    position: relative;
                    width: 32%;
                    margin-right: 3%;

                    .p-top-interview__unordered__list__link__left__ymd {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: inline-block;
                        padding: 10px 15px;
                        font-size: 13px;
                        color: #fff;
                        line-height: 1.2;
                        background-color: $main-color;
                    }
                }

                .p-top-interview__unordered__list__link__right {
                    width: 65%;

                    .p-top-interview__unordered__list__link__right__company {
                        padding-bottom: 15px;
                        padding-left: 18px;
                        font-size: 16px;

                        .p-top-interview__unordered__list__link__right__company__des {
                            font-size: 12px;
                            display: block;
                        }
                    }

                    .p-top-interview__unordered__list__link__right__copy {
                        display: block;
                        padding-top: 25px;
                        padding-bottom: 25px;
                        padding-left: 18px;
                        font-size: 20px;
                        color: $main-color;
                        border-top: 1px dotted #e1e1e1;
                    }

                    .p-top-interview__unordered__list__link__right__except {
                        display: block;
                        padding: 25px 18px 23px 18px;
                        background-color: #f5f5f5;
                        font-size: 13px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.p-top-soon {
    .p-top-soon__heading {
        margin: 50px 0 100px 0;
        padding: 200px 0;
        background-image: url("../img/top/soon.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        color: $main-color;
        text-align: center;
        font-size: 40px;
        font-family: 'Lato', sans-serif;
        font-weight: 900;
    }
}

@media screen and (max-width: 768px) {

    .p-top-news {
        margin-top: 0;
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: 0;
        padding-right: 0;
        display: block;

        .p-top-news__left {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            padding-bottom: 45px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: baseline;
            -ms-flex-align: baseline;
            align-items: baseline;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;

            .c-headingA {
                font-size: 45px;
            }

            .c-btnA {
                margin-top: 0;
                margin-left: 20px;
            }
        }

        .p-top-news__right {
            width: 100%;
        }
    }

    .p-top-about {
        padding-bottom: 80px;
        background-image: url("../img/top/about_spbg.jpg");

        .p-top-about__unordered {
            .p-top-about__unordered__list {
                width: 90%;
                min-width: initial;
            }
        }
    }

    .p-top-business {
        padding-top: 110px;
        padding-bottom: 10px;
        background-image: url("../img/top/business_spbg.jpg");

        .p-top-business__unordered {
            display: block;
            margin-top: 50px;

            .p-top-business__unordered__list {
                position: relative;
                width: 100%;
                margin-bottom: 110px;
                min-width: initial;

                img{
                    width: 100%;
                }

                &:nth-child(1) {
                    padding: 0;

                    .p-top-business__unordered__list__text {
                        left: 0;
                        right: 0;
                        bottom: -75px;
                    }
                }

                &:nth-child(2) {
                    padding: 0;

                    .p-top-business__unordered__list__text {
                        left: 0;
                        right: 0;
                        bottom: -75px;
                        top: auto;
                    }
                }

                .p-top-business__unordered__list__text {
                    position: absolute;
                    display: block;
                    width: 94%;
                    margin: 0 auto;
                    padding: 20px 20px;


                    .p-top-business__unordered__list__text__heading {
                        font-size: 20px;
                        line-height: 1.2;
                    }

                    .p-top-business__unordered__list__text__ja {
                        display: block;
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    .p-top-portfolio {
        height: 100%;
        margin-bottom: 130px;
        padding-top: 80px;
        padding-bottom: 230px;
        background-image: url("../img/top/portfolio_spbg.jpg");

        .p-top-portfolio__visual {
            display: block;
            width: 400px;
            margin-left: -70px;
            bottom: -60px;
            left: auto;
        }

        .p-top-portfolio__wrap {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            margin: 0;

            .p-top-portfolio__wrap__text {
                margin: 40px 0 25px 0;
            }
        }
    }

    .p-top-interview {

        &:after {
            top: auto;
            bottom: 0;
            width: 90%;
            height: 300px;
        }

        .p-container {
            padding-bottom: 20px;

            .c-btnC {
                top: auto;
                right: 15px;
                bottom: 40px;
            }
        }

        .p-top-interview__unordered {

            margin-top: 50px;

            .p-top-interview__unordered__list {
                padding: 20px;
                margin-right: auto;
                margin-left: auto;
                width: 100%;
                max-width: 600px;

                .p-top-interview__unordered__list__link {
                    display: block;

                    .p-top-interview__unordered__list__link__left {
                        width: 100%;
                        margin-right: 0;
                    }

                    .p-top-interview__unordered__list__link__right {
                        width: 100%;

                        .p-top-interview__unordered__list__link__right__company {
                            padding-top: 15px;
                            padding-bottom: 15px;
                            padding-left: 0;
                            font-size: 18px;

                            .p-top-interview__unordered__list__link__right__company__des {
                                font-size: 12px;
                                display: block;
                            }
                        }

                        .p-top-interview__unordered__list__link__right__copy {
                            padding-top: 20px;
                            padding-bottom: 20px;
                            padding-left: 0px;
                            font-size: 18px;
                        }

                        .p-top-interview__unordered__list__link__right__except {
                            padding: 22px 15px 20px 15px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}