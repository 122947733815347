@charset "utf-8";

.c-headingA {
    font-size: 65px;
    font-family: 'Lato';
    font-weight: 900;
    color: #000;
    line-height: 1.3;
    .c-headingA__la-ja {
        display: block;
        position: relative;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "小塚ゴシック";
        padding-left: 35px;

        &:before {
            content: "";
            width: 25px;
            height: 1px;
            background-color: #000;
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
        }
    }
}

.c-headingA--w {
    color: #fff;

    .c-headingA__la-ja {

        &:before {
            background-color: #fff;
        }
    }
}

.c-headingB {
    padding: 6px;
    background-color: #f5f5f5;

    .c-headingB__text {
        padding: 18px 35px 18px 40px;
        position: relative;
        font-size: 22px;
        background-color: #FFF;
        border: 1px solid #ddd;
        color: $main-color;
        &::before {
            position: absolute;
            content: "";
            display: block;
            width: 7px;
            height: 40px;
            background-color: $main-color;
            left: 18px;
            top: 50%;
            margin-top: -22px;
        }
    }
}

.c-headingC {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    font-size: 19px;
    color: $main-color;
    border-bottom: 1px solid #ddd;

    &::before {
        position: absolute;
        content: "";
        display: block;
        width: 225px;
        height: 5px;
        background-color: $main-color;
        left: 0;
        bottom: -5px;
    }
}

.c-headingD {
    text-align: center;
    font-size: 60px;
    font-family: 'Lato';
    font-weight: 900;
    color: #000;
    line-height: 1.2;
    letter-spacing: 1px;
    margin-bottom: 50px;
    .c-headingD__la-ja {
        display: block;
        font-size: 13px;
        font-weight: bold;
        margin-top: 10px;
        font-family: "小塚ゴシック";
    }
}

.c-headingE {

}


@media screen and (min-width: 1025px) {

    .c-headingD {

        .c-headingD__break {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {

    .c-headingA {
        font-size: 50px;

        .c-headingA__la-ja {
            padding-left: 22px;

            &:before {
                width: 15px;
            }
        }
    }

    .c-headingD {
        font-size: 40px;
        line-height: 1.1;

        .c-headingD__la-ja {
            margin-top: 10px;
        }
    }
}