@charset "utf-8";

.u-shiny-btn {

    @media screen and (min-width: 769px) {

        display: inline-block;
        position: relative;
        text-decoration: none;
        overflow: hidden;
        &:hover {
            text-decoration: none;
            color: $main-color;
            opacity: 1;
        }
        &::before {
            position: absolute;
            content: '';
            display: inline-block;
            top: -180px;
            left: 0;
            width: 30px;
            height: 100%;
            background-color: $main-color;
            animation: u-shiny-btn 3s ease-in-out infinite;
        }
    }

    @-webkit-keyframes u-shiny-btn {
        0% {
            -webkit-transform: scale(0) rotate(45deg);
            opacity: 0;
        }
        80% {
            -webkit-transform: scale(0) rotate(45deg);
            opacity: 0.5;
        }
        81% {
            -webkit-transform: scale(4) rotate(45deg);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(50) rotate(45deg);
            opacity: 0;
        }
    }
}