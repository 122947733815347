@charset "utf-8";

.p-portfolio {
    padding-bottom: 80px;

    .p-portfolio__text {
        margin: 20px 0 40px 0;
    }

    .p-portfolio__unordered {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .p-portfolio__unordered__list {
            position: relative;
            width: 31%;
            margin-right: 3.5%;
            margin-bottom: 35px;
            text-align: center;

            &:nth-child(3n) {
                margin-right: 0;
            }

            [class*="p-portfolio__unordered__list__category--"] {
                font-family: 'Lato', sans-serif;
                font-weight: normal;
                display: inline-block;
                width: 75px;
                padding: 10px;
                position: absolute;
                left: 20px;
                top: 20px;
                border-radius: 50px;
                color: #fff;
                text-align: center;
                line-height: 1;
                letter-spacing: 1px;
            }

            .p-portfolio__unordered__list__category--ipo {
                background-color: $main-color;
            }

            .p-portfolio__unordered__list__category--ma {
                background-color: $accent-color;
            }

            .p-portfolio__unordered__list__title {
                margin-top: 20px;
                font-size: 15px;
                font-weight: normal;
            }
        }
    }
}


@media screen and (max-width: 768px) {

    .p-portfolio {

        .p-portfolio__unordered {

            .p-portfolio__unordered__list {
                width: 49%;
                margin-right: 2%;

                &:nth-child(3n) {
                    margin-right: 2%;
                }
                &:nth-child(2n) {
                    margin-right: 0;
                }

                [class*="p-portfolio__unordered__list__category--"] {
                    width: 65px;
                    padding: 10px;
                    left: 10px;
                    top: 10px;
                    font-size: 12px;
                }

                .p-portfolio__unordered__list__title {
                    margin-top: 10px;
                    font-size: 13px;
                }
            }
        }
    }
}