@charset "utf-8";

.p-interview {

    .p-interview__unordered {
        padding-bottom: 110px;

        .p-interview__unordered__list {
            margin-top: 30px;
            padding: 30px;
            border: 1px solid #e1e1e1;
            background-color: #FFF;

            .p-interview__unordered__list__link {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;

                .p-interview__unordered__list__link__left {
                    position: relative;
                    width: 32%;
                    margin-right: 3%;

                    .p-interview__unordered__list__link__left__ymd {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: inline-block;
                        padding: 10px 15px;
                        font-size: 13px;
                        color: #fff;
                        line-height: 1.2;
                        background-color: $main-color;
                    }
                }

                .p-interview__unordered__list__link__right {
                    width: 65%;

                    .p-interview__unordered__list__link__right__company {
                        padding-bottom: 15px;
                        padding-left: 18px;
                        font-size: 16px;

                        .p-interview__unordered__list__link__right__company__des {
                            font-size: 12px;
                            display: block;
                        }
                    }

                    .p-interview__unordered__list__link__right__copy {
                        display: block;
                        padding-top: 25px;
                        padding-bottom: 25px;
                        padding-left: 18px;
                        font-size: 20px;
                        color: $main-color;
                        border-top: 1px dotted #e1e1e1;
                    }

                    .p-interview__unordered__list__link__right__except {
                        display: block;
                        padding: 25px 18px 23px 18px;
                        background-color: #f5f5f5;
                        font-size: 13px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .p-interview__contents {
        max-width: 940px;
        margin: 0 auto;
        padding-bottom: 50px;

        > img {
            margin-bottom: 50px;
        }

        .p-interview__contents__company__box{
            display: flex;
            .p-interview__contents__company {
                margin-bottom: 0;
                padding-bottom: 40px;
                font-size: 18px;
                font-weight: bold;
                flex: 1 1 auto;
                margin-right: 30px;

                .p-interview__contents__ymd {
                    display: inline-block;
                    margin-bottom: 47px;
                    padding: 10px 15px;
                    font-size: 13px;
                    color: #fff;
                    line-height: 1.2;
                    background-color: $main-color;
                }

                .p-interview__contents__company__ttl{
                }

                .p-interview__contents__company__des {
                    font-size: 12px;
                    font-weight: normal;
                    display: block;
                }
            }
            .p-interview__contents__company__logo{
                flex: 0 1 auto;
                width: 20%;
            }
        }

        .p-interview__contents__title {
            font-size: 26px;
            margin-bottom: 30px;
            color: $main-color;
        }

        .gallery {
            img {
                width: 100%;
            }
        }


        h2 {
            margin-bottom: 20px;
            padding: 18px 35px 18px 40px;
            position: relative;
            font-size: 22px;
            border: 5px solid #f5f5f5;
            color: $main-color;

            &::before {
                position: absolute;
                content: "";
                display: block;
                width: 7px;
                height: 40px;
                background-color: $main-color;
                left: 18px;
                top: 50%;
                margin-top: -22px;
            }
        }

        h3 {
            margin-bottom: 20px;
            position: relative;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            font-size: 19px;
            color: $main-color;
            border-bottom: 1px solid #ddd;

            &::before {
                position: absolute;
                content: "";
                display: block;
                width: 225px;
                height: 5px;
                background-color: $main-color;
                left: 0;
                bottom: -5px;
            }
        }

        h4 {
            border-bottom: double 5px $main-color;
            color: $main-color;
            padding: 10px 0 5px 0;
            font-size: 17px;
            margin-bottom: 18px;

            &::before {
                position: relative;
                top: -7px;
                content: url("../img/interview/h4_deco.png");
            }
        }

        h5 {
            display: inline-block;
            border-bottom: solid 2px $main-color;
            color: $main-color;
            font-size: 16px;
            margin-bottom: 18px;

            &::before {
                content: "■";
                color: $main-color;
                margin: 0 5px;
            }
        }

        h6 {
            margin-bottom: 20px;
            display: block;
            padding: 15px 15px;
            background-color: $main-color;
            font-size: 15px;
            color: #fff;
            line-height: 1;
            letter-spacing: 1px;
        }

        a{
            color: $main-color;
            font-weight: bold;
            padding-bottom: 5px;
            border-bottom: solid 1px $main-color;
            line-height: 30px;
            &::before {
                content: url("../img/common/icon_link.png");
                margin-right: 5px;
            }
        }

        ol {
            margin: 0 0 50px 0;

            li {
                position: relative;
                margin-bottom: 8px;
                padding: 5px 0 5px 45px;

                &:nth-child(1) {
                    &:before {
                        content: "1";
                    }
                }

                &:nth-child(2) {
                    &:before {
                        content: "2";
                    }
                }

                &:nth-child(3) {
                    &:before {
                        content: "3";
                    }
                }

                &:nth-child(4) {
                    &:before {
                        content: "4";
                    }
                }

                &:nth-child(5) {
                    &:before {
                        content: "5";
                    }
                }

                &:nth-child(6) {
                    &:before {
                        content: "6";
                    }
                }

                &:nth-child(7) {
                    &:before {
                        content: "7";
                    }
                }

                &:nth-child(8) {
                    &:before {
                        content: "8";
                    }
                }

                &:nth-child(9) {
                    &:before {
                        content: "9";
                    }
                }

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                    width: 30px;
                    height: 30px;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    color: #fff;
                    line-height: 30px;
                    background-color: $main-color;
                }
            }
        }

        ul {
            margin: 0 0 50px 0;

            li {
                position: relative;
                margin-bottom: 8px;
                padding: 5px 0 5px 25px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: 12px;
                    display: block;
                    width: 5px;
                    height: 5px;
                    border-radius: 100%;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    background-color: $main-color;
                }
            }
        }

        img{
            width: 100%;
        }

        //dl {
        //    margin: 0 0 50px 0;
        //    display: -webkit-box;
        //    display: -ms-flexbox;
        //    display: flex;
        //    -webkit-flex-wrap: wrap;
        //    -ms-flex-wrap: wrap;
        //    flex-wrap: wrap;
        //    font-weight: bold;
        //
        //    dt {
        //        width: 25%;
        //        margin-bottom: 8px;
        //        padding: 15px 20px;
        //        background-color: #f5f5f5;
        //        font-size: 15px;
        //        color: $main-color;
        //    }
        //
        //    dd {
        //        width: 75%;
        //        margin-bottom: 8px;
        //        padding: 15px 20px;
        //
        //        a {
        //            color: $main-color;
        //            text-decoration: underline;
        //        }
        //    }
        //}

        table {
            margin: 0 0 50px 0;
            width: 100%;
            max-width: 1020px;

            th {
                padding: 20px 10px;
                background-color: $main-color;
                border-left: 1px solid #ddd;
                color: #fff;
                text-align: center;

                &:first-child {
                    border: none;
                }
            }

            tr {

                &:nth-child(even) {
                    td {
                        background: #eef0fa;
                    }
                }

                td {
                    padding: 20px 10px;
                    border-left: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                    text-align: center;

                    &:first-child {
                        border-left: none;
                    }
                }
            }
        }

        blockquote {
            margin: 0 0 50px 0;
            position: relative;
            padding: 30px 90px;
            background-color: #eef0fa;
            letter-spacing: 1px;
            line-height: 1.8;
            color: $main-color;

            &:before {
                content: url(../img/common/icon_blockquote.png);
                position: absolute;
                left: 30px;
                top: 25px;
            }

            &:after {
                content: url(../img/common/icon_blockquote.png);
                position: absolute;
                right: 30px;
                bottom: 25px;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
            }
        }


        p {
            margin-bottom: 50px;
        }
    }

    .p-interview__pager {
        margin-bottom: 140px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        [class*="p-interview__pager__"] {
            position: relative;
            display: block;
            width: 100%;
            max-width: 200px;
            margin: 0 10px;
            padding: 15px 0;
            text-align: center;
            color: #fff;
            background-color: $main-color;
        }

        .p-interview__pager__prev {
            &:after {
                position: absolute;
                top: auto;
                left: 20px;
                content: "\f104";
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
            }
        }

        .p-interview__pager__next {
            &:after {
                position: absolute;
                top: auto;
                right: 20px;
                content: "\f105";
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
            }
        }
    }


}


@media screen and (max-width: 768px) {


    .p-interview {

        .p-interview__unordered {

            margin-top: 50px;

            .p-interview__unordered__list {
                padding: 20px;
                margin-right: auto;
                margin-left: auto;
                width: 100%;
                max-width: 600px;

                .p-interview__unordered__list__link {
                    display: block;

                    .p-interview__unordered__list__link__left {
                        width: 100%;
                        margin-right: 0;
                    }

                    .p-interview__unordered__list__link__right {
                        width: 100%;

                        .p-interview__unordered__list__link__right__company {
                            padding-top: 15px;
                            padding-bottom: 15px;
                            padding-left: 0;
                            font-size: 18px;

                            .p-interview__unordered__list__link__right__company__des {
                                font-size: 12px;
                                display: block;
                            }
                        }

                        .p-interview__unordered__list__link__right__copy {
                            padding-top: 20px;
                            padding-bottom: 20px;
                            padding-left: 0px;
                            font-size: 18px;
                        }

                        .p-interview__unordered__list__link__right__except {
                            padding: 22px 15px 20px 15px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .p-interview__contents {
            padding-bottom: 20px;
            .p-interview__contents__company__box{
                flex-direction: column;
                .p-interview__contents__company{
                    margin-right: 0;
                    order: 2;
                }
                .p-interview__contents__company__logo{
                    order: 1;
                    width: 50%;
                    margin: 0 auto;
                    margin-bottom: 30px;
                }
            }
        }

        .p-interview__pager {
            margin-bottom: 110px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            [class*="p-interview__pager__"] {
                max-width: 300px;
                margin: 0 1px;
                padding: 25px 0;
            }
        }
    }
}