@charset "utf-8";

.c-tableA {
    width: 100%;
    max-width: 1020px;

    .c-tableA__header {
        padding: 20px 10px;
        background-color: $main-color;
        border-left: 1px solid #ddd;
        color: #fff;
        text-align: center;

        &:first-child {
            border: none;
        }
    }

    tr {

        &:nth-child(even) {
            .c-tableA__data {
                background: #eef0fa;
            }
        }

        .c-tableA__data {
            padding: 20px 10px;
            border-left: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            text-align: center;

            &:first-child {
                border-left: none;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .scroll {
        overflow-x: scroll;
    }

    .c-tableA {
        width: 1020px;
    }
}