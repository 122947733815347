@charset "utf-8";

/* 基本のスタイル */

@media screen and (min-width: 768px) {
    .delighter {
        transition: transform 1.0s cubic-bezier(.77, 0, .175, 1) .4s, -webkit-transform 1.2s cubic-bezier(.77, 0, .175, 1) .4s;
        transform: translateX(-100vw);
        opacity: 0;
    }

    /* スタート時のスタイル */
    .delighter.started {
        transform: none;
        opacity: 1;
    }

    .u-delighter-right.delighter {
        transition: transform 1.2s cubic-bezier(.77, 0, .175, 1) .4s, -webkit-transform 1.2s cubic-bezier(.77, 0, .175, 1) .4s;
        transform: translateX(100vw);
        opacity: 0;
    }

    .u-delighter-right.delighter.started {
        transform: none;
        opacity: 1;
    }
}