@charset "utf-8";

[class*="p-container"] {
    width: 100%;
    max-width: 1230px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.p-container--800 {
    max-width: 830px;
}