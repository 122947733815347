@charset "utf-8";

.p-company {

    .p-company__vision {
        padding-top: 10px;
        padding-bottom: 60px;

        .p-company__vision__box {
            position: relative;

            .p-company__vision__contents {

                .p-company__vision__inner {
                    position: relative;
                }

                .p-company__vision__pic {
                    position: absolute;
                    top: 66px;
                    right: 0;
                    max-width: 70%;
                    z-index: -1;
                }

                .p-company__vision__heading {
                    position: relative;
                    padding-top: 161px;
                    margin-bottom: 153px;

                    .p-company__vision__heading__ttl {

                    }

                    .p-company__vision__heading__subttl {
                        padding: 18px;
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 26px;
                        color: #000000;
                        letter-spacing: 4.4px;

                        span {
                            color: #a80000;
                        }
                    }
                }

                .p-company__vision__line {
                    border-top: solid 2px #000;
                    width: 25%;
                    margin-left: 0;
                    margin-bottom: 52px;
                }

                .p-company__vision__text {
                    line-height: 1.8;
                    display: flex;
                    justify-content: space-between;

                    .p-company__vision__text__item {
                        width: 45%;
                        line-height: 28px;
                    }
                }
            }

            .p-company__vision__contents::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                z-index: -2;
                width: 35vw;
                max-width: 45%;
                height: 320px;
                max-height: 30vh;
                background-color: #38457d;
            }
        }
    }

    .p-company__concept {
        padding-top: 10px;
        padding-bottom: 60px;

        .p-company__concept__box {
            background-image: url("../img/company/concept_bg.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            max-width: 1600px;
            margin: 0 auto;
            padding: 130px 0;

            .p-company__concept__contents {
                display: flex;
                justify-content: center;
                position: relative;
                top: 30px;
                max-width: 1230px;
                margin: 0 auto;

                .p-company__concept__contents__left {
                    [class*="p-company__concept__left__heading--"] {
                        width: 85%;
                        padding: 20px 5%;
                        border: solid 2px;
                        font-size: 22px;
                        line-height: 36px;
                        letter-spacing: 5px;
                        text-align: center;
                        position: relative;
                        z-index: 2;

                        .p-company__concept__left__heading__text {
                            margin: 0 auto;
                            display: inline-block;
                            text-align: left;
                        }
                    }

                    .p-company__concept__left__heading--blue {
                        color: #38457d;
                        border-color: #38457d;
                        .p-company__concept__left__heading__text{
                            @media screen and (max-width: 1024px){
                                br{
                                    display: none;
                                }
                            }
                        }
                    }

                    .p-company__concept__left__heading--red {
                        color: #aa0707;
                        border-color: #aa0707;
                    }

                    .p-company__concept__left__des {
                        max-width: 470px;
                        background-color: #ffffff;
                        padding: 13% 10%;
                        border: solid 1px #d2d2d2;
                        position: relative;
                        z-index: 1;
                        top: -15px;
                        left: 70px;
                        line-height: 28px;
                        font-size: 14px;
                    }
                }

                .p-company__concept__contents__right {
                    position: relative;
                    border: solid 1px #d2d2d2;
                    padding: 70px 5% 70px 20%;
                    top: -30px;
                    left: -100px;
                    width: 798px;

                    .p-company__concept__contents__right__heading {
                        font-size: 18px;
                        margin-bottom: 30px;
                    }

                    .p-company__concept__unordered {
                        .p-company__concept__unordered__list {
                            display: flex;

                            .p-company__concept__unordered__list__text {
                                font-size: 24px;
                                line-height: 28px;
                                max-width: 60%;
                            }

                            .p-company__concept__unordered__list__num {
                                margin-right: 30px;
                                min-width: 80px;
                            }

                            .p-company__concept__unordered__list__subtext {
                                font-size: 10px;
                            }
                        }

                        .p-company__concept__unordered__list:not(:last-child) {
                            margin-bottom: 50px;
                        }

                        .p-company__concept__unordered__list:nth-child(2) {
                            margin-left: 50px;
                        }

                        .p-company__concept__unordered__list:nth-child(3) {
                            margin-left: 100px;
                        }
                    }
                }
            }

            .p-company__concept__contents:nth-child(1) {
                padding-left: 60px;
                padding-right: 60px;
                margin-bottom: 50px;
            }

            .p-company__concept__contents:nth-child(2) {
                padding-left: 120px;
            }
        }
    }

    .p-company__overview {
        padding-top: 10px;
        padding-bottom: 60px;

        .p-company__overview__map {
            width: 100%;
            height: 530px;
            margin-top: 30px;
            border: 5px solid #38457d;
        }
    }

    .p-company__summary {
        padding-top: 10px;
        padding-bottom: 60px;

        .p-company__summary__unordered {
            margin-bottom: 30px;
            display: flex;
            flex-wrap: wrap;

            .p-company__summary__unordered__list {
                width: 49.5%;
                min-height: 310px;
                padding: 0 15px;
                text-align: center;
                line-height: 1.2;


                &:nth-child(1) {
                    border-right: solid 1px #000;
                    border-bottom: solid 1px #000;
                }

                &:nth-child(2) {
                    border-left: solid 1px #000;
                    border-bottom: solid 1px #000;
                }

                &:nth-child(3) {
                    border-right: solid 1px #000;
                    border-top: solid 1px #000;
                }

                &:nth-child(4) {
                    border-left: solid 1px #000;
                    border-top: solid 1px #000;
                }


                .p-company__summary__unordered__list__heading {
                    padding: 60px 0;

                    .p-company__summary__unordered__list__heading__subttl {
                        margin-bottom: 20px;
                    }

                    .p-company__summary__unordered__list__heading__ttl {
                        font-size: 30px;
                        margin-bottom: 30px;
                    }

                    .p-company__summary__unordered__list__heading__num {
                        display: block;
                        font-size: 25px;

                        .p-company__summary__unordered__list__heading__num__str {
                            font-size: 56px;
                        }
                    }

                    .p-company__summary__unordered__list__heading__line {
                        border-top: solid 7px #000;
                        width: 10%;
                        margin: 0 auto 30px auto;
                    }
                }
            }
        }

        .p-company__summary__update {
            text-align: center;
            font-size: 13px;
            font-weight: 700;
        }
    }

    .p-company__history {
        padding-top: 10px;
        padding-bottom: 60px;
    }

    .p-company__certification {
        padding-top: 10px;
        padding-bottom: 60px;


        .p-company__certification__col02 {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            .p-company__certification__col02__contents {
                width: 49.5%;
                margin-right: 1%;
                background-color: #f5f5f5;

                &:last-child {
                    margin-right: 0;
                }

                .p-company__certification__col02__contents__heading {
                    display: block;
                    text-align: center;
                    font-size: 48px;
                    border-bottom: 5px solid #fff;
                    font-family: 'Lato', sans-serif;
                    font-weight: 900;
                }

                .p-company__certification__col02__contents__text {
                    display: block;
                    padding: 25px 40px;
                }
            }
        }
    }

    .p-company__fund {
        padding-top: 10px;
        padding-bottom: 60px;
    }

    .p-company__gallery {
        padding-top: 10px;
        padding-bottom: 150px;

        .p-company__gallery__unordered {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;

            .p-company__gallery__unordered__list {
                width: 22%;
                margin-right: 4%;
                margin-bottom: 30px;
                text-align: center;

                &:nth-child(4n) {
                    margin-right: 0;
                }

                img {
                    object-fit: contain;
                    height: 264px;
                    @media screen and (max-width: 768px) {
                        height: 164px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .p-company {

        .p-company__vision {
            .p-company__vision__contents {
                .p-company__vision__inner {
                    .p-company__vision__pic {
                        top: 45px;
                    }

                    .p-company__vision__heading {
                        padding-top: 210px;
                        margin-bottom: 45px;
                    }

                    .p-company__vision__text {
                        flex-direction: column;

                        .p-company__vision__text__item {
                            width: auto;
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }

        .p-company__concept {
            padding-left: 5vw;
            padding-right: 5vw;

            .p-company__concept__box {
                background-image: url("../img/company/concept_spbg.png");
                background-position: top;
                padding: 30px 0;

                .p-company__concept__contents {
                    flex-wrap: wrap;

                    .p-company__concept__contents__left {
                        [class*="p-company__concept__left__heading--"] {
                            width: 90%;
                            text-align: left;
                            padding-left: 10px;

                            .p-company__concept__left__heading__text {
                                font-size: 4.5vw;
                                line-height: 6vw;
                            }
                        }

                        .p-company__concept__left__des {
                            width: 90%;
                            top: -20px;
                            left: 0;
                            right: 0;
                            margin-left: auto;
                            padding: 30px 20px;
                            border: 0;
                        }
                    }

                    .p-company__concept__contents__right {
                        left: 0;
                        padding: 15vw 3vw 5vw 3vw;
                        top: -15vw;
                        margin-right: 5vw;

                        .p-company__concept__contents__right__heading {
                            font-size: 4vw;
                        }

                        .p-company__concept__unordered {
                            .p-company__concept__unordered__list {
                                .p-company__concept__unordered__list__text {
                                    font-size: 4.4vw;
                                    br {
                                        display: none;
                                    }
                                }

                                .p-company__concept__unordered__list__num {
                                    width: 15vw;
                                    margin-right: 5vw;
                                }

                                .p-company__concept__unordered__list__subtext {
                                    font-size: 1.5vw;
                                }
                            }

                            .p-company__concept__unordered__list:nth-child(2) {
                                margin-left: 0;
                            }

                            .p-company__concept__unordered__list:nth-child(3) {
                                margin-left: 0;
                            }
                        }
                    }
                }

                .p-company__concept__contents:nth-child(1) {
                    padding: 0 5vw;
                    margin-bottom: 0;
                }

                .p-company__concept__contents:nth-child(2) {
                    padding: 0 5vw;
                }
            }
        }

        .p-company__overview {

            .p-company__overview__map {
                height: 200px;
                margin-top: 10px;
                border: 2px solid #38457d;
            }
        }

        .p-company__summary {

            .p-company__summary__unordered {
                display: block;

                .p-company__summary__unordered__list {
                    width: 100%;
                    min-height: 1px;
                    margin-right: 0;
                    padding: 30px 15px;
                    font-size: 28px;


                    &:not(:last-child){
                        border: none;
                        border-bottom: solid 2px #000;
                    }
                    &:last-child{
                        border: none;
                    }

                    .p-company__summary__unordered__list__heading {
                        padding: 0;

                        .p-company__summary__unordered__list__heading__num {
                            margin-top: 15px;
                            font-size: 30px;
                        }
                        .p-company__summary__unordered__list__heading__subttl{
                            font-size: 13px;
                        }
                        .p-company__summary__unordered__list__heading__ttl{
                            font-size: 20px;
                        }
                        .p-company__summary__unordered__list__heading__line{
                            width: 40px;
                        }
                    }
                }
            }
            .p-company__summary__update{
                display: none;
            }
        }

        .p-company__certification {
            padding-bottom: 20px;

            .p-company__certification__col02 {
                display: block;

                .p-company__certification__col02__contents {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 40px;
                    background: none;

                    .p-company__certification__col02__contents__heading {
                        position: relative;
                        z-index: 1;
                        display: inline-block;
                        width: 270px;
                        margin-left: -15px;
                        padding: 20px 15px;
                        text-align: left;
                        font-size: 36px;
                        border-bottom: none;
                        background-color: #f5f5f5;
                    }

                    .p-company__certification__col02__contents__text {
                        position: relative;
                        z-index: 1;
                        display: block;
                        margin-top: -15px;
                        padding: 25px 30px;
                        border: 1px solid #000;
                    }
                }
            }
        }

        .p-company__gallery {
            padding-bottom: 100px;

            .p-company__gallery__unordered {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;

                .p-company__gallery__unordered__list {
                    width: 47%;
                    margin-right: 6%;
                    margin-bottom: 15px;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }
        }

    }
}