@charset "utf-8";

.p-business {
    padding-bottom: 110px;

    .c-headingD {
        font-size: 48px;
    }

    .p-business__range {
        display: flex;
        justify-content: space-between;

        .p-business__range__text {
            width: 35%;

            .p-business__range__text__ttl {
                .p-business__range__text__ttl__en {
                    @extend .u-ff--lato;
                    font-size: 64px;
                    line-height: 64px;
                    color: #38457d;
                    margin-bottom: 30px;
                }

                .p-business__range__text__ttl__jp {
                    font-size: 20px;
                    margin-bottom: 30px;
                }
            }

            .p-business__range__text__des {
                font-size: 26px;
                max-width: 400px;
            }
        }

        .p-business__range__unordered {
            width: 60%;
            display: flex;
            padding-left: 10%;
            justify-content: space-between;
            flex-wrap: wrap;

            .p-business__range__unordered__list {
                width: 45%;
                border: solid 2px #000;
                text-align: center;
                padding: 0 5%;
                margin-bottom: 40px;

                &::before {
                    position: relative;
                    left: -75%;
                    @extend .u-ff--lato;
                }

                &:nth-child(1) {
                    .p-business__range__unordered__list__ttl {
                        padding: 14px 0 38px 0;
                    }

                    &::before {
                        content: '01';
                    }
                }

                &:nth-child(2)::before {
                    content: '02';
                }

                &:nth-child(3)::before {
                    content: '03';
                }

                &:nth-child(4)::before {
                    content: '04';
                }

                .p-business__range__unordered__list__line {
                    border-top: solid 4px #000;
                }

                .p-business__range__unordered__list__ttl {
                    padding: 40px 0 60px 0;
                    font-size: 30px;
                }

                .p-business__range__unordered__list__subttl {
                    margin-top: -20px;
                }

            }
        }
    }

    .p-business__venture {

        .p-business__venture__start {
            border-top: solid 1px #000;
            margin-bottom: 40px;
        }

        .p-business__venture__contents {
            display: flex;

            .p-business__venture__text {
                flex: 1 1 0;
                padding-right: 5%;
            }

            .p-business__venture__unordered {
                flex: 1 1 0;
                padding-left: 5%;
                display: flex;
                justify-content: space-between;

                .p-business__venture__unordered__list {
                    width: 45%;

                    .p-business__venture__unordered__list__ttl {
                        background-color: #000;
                        color: #fff;
                        font-size: 18px;
                        text-align: center;
                        padding: 5px 0;
                    }

                    .p-business__venture__unordered__list__line {
                        border-top: solid 1px #000;
                        width: 40px;
                        margin: 30px auto;
                    }
                }
            }
        }

        .p-business__venture__end {
            width: 1px;
            height: 120px;
            background-color: #000;
            margin: 40px auto;
        }
    }

    .p-business__private{
        .p-business__private__unordered{
            display: flex;
            justify-content: space-between;
            .p-business__private__unordered__list{
                width: 30%;
                .p-business__private__unordered__list__subttl{
                    font-size: 20px;
                    width: 80%;
                    min-width: 225px;
                    margin-bottom: 30px;
                }
                .p-business__private__unordered__list__ttl {
                    background-color: #000;
                    color: #fff;
                    font-size: 18px;
                    text-align: center;
                    padding: 5px 0;
                }

                .p-business__private__unordered__list__line {
                    border-top: solid 1px #000;
                    width: 40px;
                    margin: 30px auto;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .p-business {

        .c-headingD {
            text-align: left;
            font-size: 40px;
        }

        .p-business__range {
            flex-direction: column;

            .p-business__range__text {
                width: 100%;
                margin-bottom: 40px;

                .p-business__range__text__ttl {
                    .p-business__range__text__ttl__en {
                        font-size: 11vw;
                        line-height: 11vw;
                    }
                }

                .p-business__range__text__des {
                    max-width: initial;
                }
            }

            .p-business__range__unordered {
                width: 100%;
                padding-left: 0;

                .p-business__range__unordered__list {
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;

                    &::before {
                        left: -65%;
                    }
                }
            }
        }

        .p-business__venture {
            margin-bottom: 30px;
            .p-business__venture__contents {
                flex-direction: column;

                .p-business__venture__text {
                    margin-bottom: 30px;
                }

                .p-business__venture__unordered {
                    flex-direction: column;
                    border-left: solid 1px #000;
                    margin-left: 10%;
                    padding-top: 50px;
                    padding-bottom: 50px;

                    .p-business__venture__unordered__list {
                        min-width: 170px;
                        width: 90%;
                        &:not(:last-child){
                            margin-bottom: 100px;
                        }
                    }
                }
            }

            .p-business__venture__end {
                display: none;
            }
        }
        .p-business__private{
            .c-headingD{
                margin-bottom: 30px;
            }
            .p-business__private__unordered{
                flex-direction: column;
                border-left: solid 1px #000;
                margin-left: 10%;
                padding-top: 50px;
                padding-bottom: 50px;

                .p-business__private__unordered__list {
                    min-width: 170px;
                    width: 90%;
                    padding-left: 5%;
                    &:not(:last-child){
                        margin-bottom: 100px;
                    }
                    .p-business__private__unordered__list__subttl{
                        min-width: initial;
                        width: 100%;
                    }
                }
            }
        }
    }
}