@charset "utf-8";

.c-breadcrumbs {
    font-size: 1.2rem;
    width: 100%;
    padding: 15px 0;
    color: #000;

    ul,
    ol {
        list-style: none;
        padding: 0;
    }
    li {
        display: inline;
        margin: 0;
        &:before {
            content: ">";
            margin: 0 15px;
        }
        &:first-child:before {
            display: none;
        }
    }
    a {
        color: #000;
        text-decoration: none;
    }
}

.under-visual {

    .c-breadcrumbs {
        padding: 15px 0;
        position: absolute;
        left: 0;
        bottom: 0;
        color: #fff;
        background-color: rgba(56, 69, 125, 0.8);

        a {
            color: #fff;
        }
    }
}



@media screen and (max-width: 500px) {
    .breadcrumbs {
        font-size: 1rem;
    }
}