@charset "utf-8";

.p-contact {
    .p-contact__flow {
        margin-top: 65px;
        margin-bottom: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #fff;

        .p-contact__flow__step {
            width: 34%;
            padding: 25px 15px;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            &:nth-child(1) {
                background-image: url(../img/contact/flow01.png);
            }

            &:nth-child(2) {
                background-image: url(../img/contact/flow02.png);
                margin: 0 -1%;
            }

            &:nth-child(3) {
                background-image: url(../img/contact/flow03.png);
            }
        }

        .p-contact__flow__step--current {
            width: 34%;
            padding: 25px 15px;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            &:nth-child(1) {
                background-image: url(../img/contact/flow01_current.png);
            }

            &:nth-child(2) {
                background-image: url(../img/contact/flow02_current.png);
                margin: 0 -1%;
            }

            &:nth-child(3) {
                background-image: url(../img/contact/flow03_current.png);
            }
        }
    }
    
    .p-contact__back-send-container{
        display: flex;
        justify-content: center;
        @media screen and (max-width: 768px){
            flex-direction: column;
        }
        .c-form__confirmation{
            margin: 0 50px;
            @media screen and (max-width: 768px){
                margin: 5px auto;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .p-contact {
        .p-contact__flow {
             margin-top:30px;
             margin-bottom:10px;

            .p-contact__flow__step,
            .p-contact__flow__step--current {
                padding: 15px 10px;
            }
        }
    }
}

