@charset "utf-8";

.c-btnA{
    position: relative;
    display: block;
    width: 100%;
    max-width: 240px;
    padding:20px;
    line-height: 1.2;
    border: 1px solid $main-color;
    color: $main-color;
    font-weight: bold;

    &:after{
        content:"";
        width: 30px;
        height: 1px;
        background-color: $main-color;
        display: block;
        position: absolute;
        top:50%;
        right:0;
    }
}

.c-btnB{
    position: relative;
    display: block;
    width: 100%;
    max-width: 240px;
    padding:20px;
    line-height: 1.2;
    border: 1px solid $accent-color;
    color: $accent-color;
    text-align: center;
}

.c-btnC{
    position: relative;
    display: block;
    width: 100%;
    max-width: 240px;
    padding:20px;
    line-height: 1.2;
    border: 1px solid #fff;
    color: #fff;
    font-weight: bold;

    &:after{
        content:"";
        width: 30px;
        height: 1px;
        background-color: #fff;
        display: block;
        position: absolute;
        top:50%;
        right:0;
    }
}

.c-btnD{
    position: relative;
    display: block;
    width: 100%;
    max-width: 240px;
    padding:20px;
    line-height: 1.2;
    color: #fff;
    font-weight: bold;
    background-color: #38457d;

    &:after{
        content:"";
        width: 30px;
        height: 1px;
        background-color: #fff;
        display: block;
        position: absolute;
        top:50%;
        right:0;
    }
}

