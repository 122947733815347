@charset "utf-8";

    .c-orderedA {
        margin-bottom: 60px;

        .c-orderedA__list {
            position: relative;
            margin-bottom: 8px;
            padding: 5px 0 5px 45px;

            &:nth-child(1) {
                &:before {
                    content: "1";
                }
            }

            &:nth-child(2) {
                &:before {
                    content: "2";
                }
            }

            &:nth-child(3) {
                &:before {
                    content: "3";
                }
            }

            &:nth-child(4) {
                &:before {
                    content: "4";
                }
            }

            &:nth-child(5) {
                &:before {
                    content: "5";
                }
            }

            &:nth-child(6) {
                &:before {
                    content: "6";
                }
            }

            &:nth-child(7) {
                &:before {
                    content: "7";
                }
            }

            &:nth-child(8) {
                &:before {
                    content: "8";
                }
            }

            &:nth-child(9) {
                &:before {
                    content: "9";
                }
            }

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 30px;
                height: 30px;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                color: #fff;
                line-height: 30px;
                background-color: $main-color;
            }
        }
    }

    .c-unorderedA {
        margin-bottom: 60px;

        .c-unorderedA__list {
            position: relative;
            margin-bottom: 8px;
            padding: 5px 0 5px 25px;

            &:before {
                content: "";
                position: absolute;
                left: 7px;
                top: 12px;
                display: block;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                background-color: $main-color;
            }
        }
    }

    .c-definitionA {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        font-weight: bold;

        .c-definitionA__term {
            width: 25%;
            margin-bottom: 8px;
            padding: 15px 20px;
            background-color: #f5f5f5;
            font-size: 15px;
            color: $main-color;
        }

        .c-definitionA__des {
            width: 75%;
            margin-bottom: 8px;
            padding: 15px 20px;
            font-weight: normal;
        }
    }


@media screen and (max-width: 768px) {
            .c-definitionA {

            .c-definitionA__term {
                width: 100%;
                margin-bottom: 0;
            }

            .c-definitionA__des {
                width: 100%;
            }
        }
    }