@charset "utf-8";

.under-visual {
    position: relative;
    padding-top: 110px;
    margin-bottom: 100px;
    .heading {
        display: block;
        padding: 155px 15px;
        font-size: 56px;
        color: #fff;
        line-height: 1.2;
        word-break: break-all;
        text-align: center;
        background-image: url("../img/common/under_visual_bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;

        .des {
            display: block;
            margin-top: 5px;
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 1024px) {

    .under-visual {
        padding-top: 68px;
        margin-bottom: 50px;
    }
}


@media screen and (max-width: 768px) {

    .under-visual {

        .heading {
            padding: 125px 15px;
            font-size: 36px;
        }
    }
}
