@charset "utf-8";

.c-news {
    width: 100%;
    max-width: 1020px;

    tr {

        &:nth-child(odd) {
            background-color: #f8f8f8;
        }

        .c-news__header {
            width: 25%;
            min-width: 225px;
            padding: 20px 25px;
            color: $main-color;
            text-align: left;

            .c-news__header__category {
                display: inline-block;
                margin-left: 15px;
                padding: 3px 8px;
                background-color: $main-color;
                color: #fff;
                font-size: 12px;
                vertical-align: middle;
            }
        }

        .c-news__data {
            width: 75%;
            padding: 22px 25px 18px 5px;
            text-align: left;
        }
    }
}


@media screen and (max-width: 768px) {

    .c-news {

        tr {

            .c-news__header {
                display: block;
                width: 100%;
                padding: 15px 15px 8px 15px;

                .c-news__header__category {
                    margin-left: 10px;
                    padding: 2px 8px;
                }
            }

            .c-news__data {
                display: block;
                width: 100%;
                padding: 0 15px 15px 15px;
            }
        }
    }
}