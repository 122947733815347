@charset "utf-8";

.u-slide{

    @media screen and (min-width: 769px) {
        display: block;
        position: relative;
        color: #000;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        &:hover{
            opacity: 1;
        }
        &::before{
            content: "";
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            top: -100%;
            left: 0;
            backdrop-filter: saturate(400%);
            transition: all 0.3s ease-in-out;
        }
        &:hover::before{
            top: 0;
        }
    }
}

