@charset "utf-8";

.p-news {

    .p-news__heading {
        margin-bottom: 45px;
        padding: 30px 20px 20px 20px;
        background-color: #f5f5f5;

        .p-news__heading__ymd {
            color: $main-color;
        }

        .p-news__heading__category {
            display: inline-block;
            margin-left: 15px;
            padding: 3px 8px;
            background-color: $main-color;
            color: #fff;
            font-size: 12px;
            vertical-align: middle;
        }

        .p-news__heading__title {
            display: block;
            margin-top: 15px;

            padding: 20px 8px 0 8px;
            font-size: 24px;
            border-top: dotted 1px #ddd;
        }
    }

    .p-news__contents {
        margin-bottom: 80px;

        .pdf_link {
            position: relative;
            display: inline-block;
            padding: 15px;
            line-height: 1.2;
            color: $main-color;
            font-weight: bold;
            background-color: #e3e8ed;
            margin: 10px 0;
            border: none;

            p{
                padding-left: 45px;
                padding-right: 50px;
            }

            &::before {
                position: absolute;
                content: url("../img/common/icon_pdf.png");
                top: 4px;
            }

            &::after {
                content: "";
                width: 30px;
                height: 1px;
                background-color: $main-color;
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
            }
        }

        h4 {
            margin-bottom: 20px;
            padding: 18px 35px 18px 40px;
            position: relative;
            font-size: 22px;
            border: 5px solid #f5f5f5;
            color: #38457d;

            &:before {
                position: absolute;
                content: "";
                display: block;
                width: 7px;
                height: 40px;
                background-color: #38457d;
                left: 18px;
                top: 50%;
                margin-top: -22px;
            }
        }

        h5 {
            margin-bottom: 20px;
            display: block;
            padding: 15px 15px;
            background-color: $main-color;
            font-size: 15px;
            color: #fff;
            line-height: 1;
            letter-spacing: 1px;
        }

        h6 {
            margin-bottom: 20px;
            position: relative;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            font-size: 19px;
            color: $main-color;
            border-bottom: 1px solid #ddd;

            &::before {
                position: absolute;
                content: "";
                display: block;
                width: 225px;
                height: 5px;
                background-color: $main-color;
                left: 0;
                bottom: -5px;
            }
        }

        a{
            color: $main-color;
            font-weight: bold;
            padding-bottom: 5px;
            border-bottom: solid 1px $main-color;
            line-height: 30px;
            &::before {
                content: url("../img/common/icon_link.png");
                margin-right: 5px;
            }
        }

        ol {
            margin: 0 0 50px 0;

            li {
                position: relative;
                margin-bottom: 8px;
                padding: 5px 0 5px 45px;

                &:nth-child(1) {
                    &:before {
                        content: "1";
                    }
                }

                &:nth-child(2) {
                    &:before {
                        content: "2";
                    }
                }

                &:nth-child(3) {
                    &:before {
                        content: "3";
                    }
                }

                &:nth-child(4) {
                    &:before {
                        content: "4";
                    }
                }

                &:nth-child(5) {
                    &:before {
                        content: "5";
                    }
                }

                &:nth-child(6) {
                    &:before {
                        content: "6";
                    }
                }

                &:nth-child(7) {
                    &:before {
                        content: "7";
                    }
                }

                &:nth-child(8) {
                    &:before {
                        content: "8";
                    }
                }

                &:nth-child(9) {
                    &:before {
                        content: "9";
                    }
                }

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                    width: 30px;
                    height: 30px;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    color: #fff;
                    line-height: 30px;
                    background-color: $main-color;
                }
            }
        }

        ul {
            margin: 0 0 50px 0;

            li {
                position: relative;
                margin-bottom: 8px;
                padding: 5px 0 5px 25px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: 12px;
                    display: block;
                    width: 5px;
                    height: 5px;
                    border-radius: 100%;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    background-color: $main-color;
                }
            }
        }

        dl {
            margin: 0 0 50px 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            font-weight: bold;

            dt {
                width: 25%;
                margin-bottom: 8px;
                padding: 15px 20px;
                background-color: #f5f5f5;
                font-size: 15px;
                color: $main-color;
            }

            dd {
                width: 75%;
                margin-bottom: 8px;
                padding: 15px 20px;

                a {
                    color: $main-color;
                    text-decoration: underline;
                }
            }
        }

        table {
            margin: 0 0 50px 0;
            width: 100%;
            max-width: 1020px;

            th {
                padding: 20px 10px;
                background-color: $main-color;
                border-left: 1px solid #ddd;
                color: #fff;
                text-align: center;

                &:first-child {
                    border: none;
                }
            }

            tr {

                &:nth-child(even) {
                    td {
                        background: #eef0fa;
                    }
                }

                td {
                    padding: 20px 10px;
                    border-left: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                    text-align: center;

                    &:first-child {
                        border-left: none;
                    }
                }
            }
        }

        blockquote {
            margin: 0 0 50px 0;
            position: relative;
            padding: 30px 90px;
            background-color: #eef0fa;
            letter-spacing: 1px;
            line-height: 1.8;
            color: $main-color;

            &:before {
                content: url(../img/common/icon_blockquote.png);
                position: absolute;
                left: 30px;
                top: 25px;
            }

            &:after {
                content: url(../img/common/icon_blockquote.png);
                position: absolute;
                right: 30px;
                bottom: 25px;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
            }
        }
    }

    .p-news__pager {
        margin-bottom: 140px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        [class*="p-news__pager__"] {
            position: relative;
            display: block;
            width: 100%;
            max-width: 200px;
            margin: 0 10px;
            padding: 15px 0;
            text-align: center;
            color: #fff;
            background-color: $main-color;
        }

        .p-news__pager__prev {
            &:after {
                position: absolute;
                top: auto;
                left: 20px;
                content: "\f104";
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
            }
        }

        .p-news__pager__next {
            &:after {
                position: absolute;
                top: auto;
                right: 20px;
                content: "\f105";
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
            }
        }
    }
}


@media screen and (max-width: 768px) {

    .p-news {
        .p-news__contents {
            dl {

                dt {
                    width: 100%;
                    margin-bottom: 0;
                }

                dd {
                    width: 100%;
                }
            }
        }

        .p-news__pager {
            margin-bottom: 110px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            [class*="p-news__pager__"] {
                max-width: 300px;
                margin: 0 1px;
                padding: 25px 0;
            }
        }
    }
}