@charset "utf-8";

    .c-blockquoteA {
        margin: 0;
        position: relative;
        padding: 30px 90px;
        background-color: #eef0fa;
        letter-spacing: 1px;
        line-height: 1.8;
        color: $main-color;

        &:before {
            content: url(../img/common/icon_blockquote.png);
            position: absolute;
            left: 30px;
            top: 25px;
        }

        &:after {
            content: url(../img/common/icon_blockquote.png);
            position: absolute;
            right: 30px;
            bottom: 25px;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
        }
    }

    .c-linkA{
        color: $main-color;
        text-decoration: underline;
    }


@media screen and (max-width: 768px) {

.c-blockquoteA {
    padding: 70px 45px;
}
}