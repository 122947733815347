@charset "utf-8";
 /***************************

object/utility/spases.scss 

***************************/
.u-m-0 {
    margin: 0;
}

@for $i from 0 through 20{
  .u-mt-#{$i * 5}{ margin-top: #{$i * 5}px; }
}

@for $i from 0 through 20{
  .u-mb-#{$i * 5}{ margin-bottom: #{$i * 5}px; }
}

@for $i from 0 through 20{
  .u-mr-#{$i * 5}{ margin-right: #{$i * 5}px; }
}

@for $i from 0 through 20{
  .u-ml-#{$i * 5}{ margin-left: #{$i * 5}px; }
}

.u-p-0 {
    padding: 0;
}

@for $i from 0 through 20{
  .u-pt-#{$i * 5}{ padding-top: #{$i * 5}px; }
}

@for $i from 0 through 20{
  .u-pb-#{$i * 5}{ padding-bottom: #{$i * 5}px; }
}

@for $i from 0 through 20{
  .u-pr-#{$i * 5}{ padding-right: #{$i * 5}px; }
}

@for $i from 0 through 20{
  .u-pl-#{$i * 5}{ padding-left: #{$i * 5}px; }
}
