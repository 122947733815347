@charset "utf-8";

.common-contact {
    position: relative;
    padding: 55px 0 45px 0;
    background-image: url("../img/common/common_contact_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    .common-contact__pagetop {
        display: block;
        position: absolute;
        z-index: 3;
        bottom: 10px;
        right: 50px;
        font-size: 24px;
        color: $accent-color;
    }

    .common-contact__wrap {
        width: 100%;
        max-width: 670px;
        margin: 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .common-contact__heading {
        padding-bottom: 30px;
    }

    .common-contact__link {
        width: 295px;
        margin-right: 70px;

        .common-contact__link__text {
            margin-bottom: 15px;
        }

        .c-btnA,
        .c-btnB {
            width: 100%;
            max-width: 300px;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

footer {
    padding: 0;

    small {
        position: fixed;
        z-index: 4;
        top: 360px;
        right: -155px;
        display: block;
        margin: 0;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        color: #000;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}

.f-container__top-contents {
    width: 100%;
    padding: 55px 0 50px 0;
    background-color: $main-color;


    .f-container__top-contents__menu {
        display: block;
        padding: 0 0 0 60px;

        &:first-child {
            padding: 0 0 0 0;
        }

        .f-container__top-contents__menu__list {

            a {
                position: relative;
                display: block;
                margin: 0 0 15px 0;
                font-size: 15px;
                font-weight: bold;
                color: #fff;
            }
        }
        .f-container__top-contents__menu__list--pulldown {
            position: relative;
            display: block;
            font-size: 15px;
            font-weight: bold;
            color: #fff;

            a{
                color: #fff;
            }

            ul {
                padding: 5px 0 0 0;

                .f-container__top-contents__menu__list--pulldown__list {

                    a {
                        position: relative;
                        display: block;
                        margin: 8px 0 0 0;
                        font-size: 13px;
                        font-weight: normal;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.f-container__bottom-contents {
    margin-top: 35px;
    margin-bottom: 30px;

    .f-container__bottom-contents__logo {
        span {
            display: block;
            margin-top: 5px;
            margin-right: 45px;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .f-container__bottom-contents__add {
        font-size: 13px;
        font-weight: bold;
        line-height: 1.7;
    }

    .f-container__bottom-contents__fb {
        display: block;
        margin-left: auto;
        width: 28px;
    }

    .f-container__bottom-contents__sns{
        display: flex;
        margin-left: auto;
        .f-container__bottom-contents__item {
            display: block;
            width: 28px;
            &:nth-child(1){
                margin-right: 5px;
            }
        }
    }
}


@media screen and (max-width: 1024px) {


    footer {

        small {
            position: relative;
            top: auto;
            right: auto;
            margin: 35px 0 35px 0;
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
    }

    .f-container {
        display: block;
        padding-left: 0!important;
        padding-right: 0!important;

        .f-container__top-contents {
            width: 100%;
            display: block;
            padding: 0;
            border-top: 1px solid #ddd;

            .f-container__top-contents__menu {
                padding: 0;

                .f-container__top-contents__menu__list {

                    a {
                        display: block;
                        position: relative;
                        margin: 0;
                        padding: 20px 50px 20px 20px;
                        border-bottom: 1px solid #ddd;

                        &:after {
                            position: absolute;
                            right: 25px;
                            top: 13px;
                            content: "\f105";
                            font-size: 24px;
                            font-weight: 900;
                            font-family: 'Font Awesome 5 Free';
                        }
                    }
                }

                .f-container__top-contents__menu__list--pulldown {
                    cursor: pointer;
                    display: block;
                    position: relative;
                    margin: 0;
                    padding: 20px 30px 20px 20px;
                    border-bottom: 1px solid #ddd;

                    .icon,
                    .icon span {
                        display: inline-block;
                        transition: all .4s;
                        box-sizing: border-box;
                    }

                    .icon {
                        position: relative;
                        width: 10px;
                        height: 10px;
                        float: right;

                        span {

                            &:nth-of-type(1) {
                                position: absolute;
                                top: 10px;
                                left: 0px;
                                width: 16px;
                                height: 1px;
                                background-color: #fff;
                                transform: rotate(0deg);
                                -webkit-transform: rotate(0deg);
                                -moz-transform: rotate(0deg);
                                -ms-transform: rotate(0deg);
                                -o-transform: rotate(0deg);
                            }

                            &:nth-of-type(2) {
                                position: absolute;
                                top: 10px;
                                left: 0px;
                                width: 16px;
                                height: 1px;
                                background-color: #fff;
                                transform: rotate(90deg);
                                -webkit-transform: rotate(90deg);
                                -moz-transform: rotate(90deg);
                                -ms-transform: rotate(90deg);
                                -o-transform: rotate(90deg);
                            }

                            &:nth-of-type(3) {
                                position: absolute;
                                top: -3px;
                                left: -6px;
                                width: 28px;
                                height: 28px;
                                border: 1px solid #fff;
                                border-radius: 50px;
                            }
                        }
                    }

                    .icon.is-open span:nth-of-type(2) {
                        top: 10px;
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                    }

                    ul {
                        display: none;
                    }
                }
            }
        }

        .f-container__bottom-contents {
            display: block;
            margin-top: 60px;
            margin-bottom: 0;
            text-align: center;

            .f-container__bottom-contents__logo {
                span {
                    display: block;
                    margin-top: 5px;
                    margin-bottom: 15px;
                    margin-right: 0px;
                    font-size: 12px;
                }
            }

            .f-container__bottom-contents__add {
                font-size: 13px;
                font-weight: bold;
                line-height: 1.7;
            }

            .f-container__bottom-contents__fb {
                margin-top: 20px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 42px;
            }

            .f-container__bottom-contents__sns{
                display: flex;
            }
        }
    }
}


@media screen and (max-width: 768px) {

    footer {

        small {
            font-size: 10px;
            transform: scale(0.9);
        }
    }

    .common-contact {
        padding: 80px 15px;
        background-image: url("../img/common/common_contact_spbg.jpg");

        .common-contact__pagetop {
            display: none;
        }

        .common-contact__wrap {
            display: block;
        }

        .common-contact__heading {
            padding-bottom: 30px;
        }

        .common-contact__link {
            width: 100%;
            margin-right: 0;

            .common-contact__link__text {
                margin-bottom: 40px;
            }
        }
    }

    .f-container {
        .f-container__bottom-contents {


            .f-container__bottom-contents__add {
                font-size: 11px;
                font-weight: bold;
                line-height: 1.7;
                margin-bottom: 20px;
            }

            .f-container__bottom-contents__sns{
                justify-content: center;
                .f-container__bottom-contents__item{
                    width: 10%;
                    &:not(:last-child){
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}