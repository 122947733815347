@charset "utf-8";

.p-privacy {
	padding-bottom: 140px;
    line-height: 1.9;
    letter-spacing: 1px;

    .c-headingB.declaration {
        margin-bottom: 30px;
        line-height: 1.6;
    }

    .c-headingB.purpose {
    	margin-top: 80px;
        margin-bottom: 30px;
        line-height: 1.6;
    }

    .c-headingB.basic {
    	margin-top: 80px;
        margin-bottom: 30px;
        line-height: 1.6;
    }

    .p-privacy__ordered {
        padding: 20px 0 0 30px;


        .p-privacy__ordered__list {
            position: relative;
            margin-bottom: 20px;

            &:before {
                position: absolute;
                top: 0;
                left: -30px;
            }

            &:nth-child(1) {
                &:before {
                    content: "1.";
                }
            }

            &:nth-child(2) {
                &:before {
                    content: "2.";
                }
            }

            &:nth-child(3) {
                &:before {
                    content: "3.";
                }
            }

            &:nth-child(4) {
                &:before {
                    content: "4.";
                }
            }

            &:nth-child(5) {
                &:before {
                    content: "5.";
                }
            }

            &:nth-child(6) {
                &:before {
                    content: "6.";
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {

.p-privacy {
	padding-bottom: 100px;

    .c-headingB.purpose {
    	margin-top: 60px;
    }

    .c-headingB.basic {
    	margin-top: 60px;
    }

}
}