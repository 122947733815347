@charset "utf-8";

.c-form {
    padding: 0 0 110px 0;

    .c-form__form-table {
        margin: 0 0 50px 0;

        tr {
            border-bottom: 1px solid #dddddd;
        }

        .c-form__form-table__item,
        .c-form__form-table__item--other {
            position: relative;
            width: 30%;
            min-width: 250px;
            padding: 45px 20px;
            text-align: left;
            vertical-align: top;

            [class*="c-form__form-table__item__"] {
                position: absolute;
                right: 20px;
                top: auto;
                display: inline-block;
                width: 100%;
                max-width: 50px;
                padding: 3px 2px;
                font-size: 12px;
                color: #fff;
                text-align: center;
                font-weight: normal;
            }

            .c-form__form-table__item__required {
                background-color: #a80000;
            }

            .c-form__form-table__item__any {
                background-color: #999999;
            }
        }

        .c-form__form-table__item__input {
            width: 70%;
            padding: 30px 20px;

            button {
                background-color: #FFF;
                border: 1px solid #ddd;
                border-radius: 4px;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }

    .c-form__privacy {
        margin: 0 0 30px 0;
        text-align: center;

        .c-form__privacy__link {
            color: $main-color;
            text-decoration: underline;
        }
    }

    .c-form__confirmation {
        display: block;
        width: 100%;
        max-width: 290px;
        margin: 0 auto;
        padding: 25px 15px;
        border: none;
        background-color: $main-color;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        line-height: 1.2;
        cursor: pointer;
    }
}

.c-size-sss {
    widows: 100%;
    max-width: 80px;
}

.c-size-ss {
    widows: 100%;
    max-width: 140px;
}

.c-size-s {
    widows: 100%;
    max-width: 220px;
}

.c-size-m {
    widows: 100%;
    max-width: 360px;
}

.c-size-l {
    widows: 100%;
    max-width: 510px;
}

.c-size-ll {
    widows: 100%;
    max-width: 590px;
}

.c-size-xl {
    widows: 100%;
    max-width: 690px;
}




@media screen and (max-width: 768px) {

    .c-form {
        padding: 0 0 90px 0;

        .c-form__form-table {
            margin-bottom: 30px;
            font-size: 16px;

            .c-form__form-table__item,
            .c-form__form-table__item--other {
                display: block;
                width: 100%;
                padding: 20px 15px 5px 15px;

                [class*="c-form__form-table__item__"] {
                    position: relative;
                    right: auto;
                    top: -1px;
                    margin: 0 0 0 10px;
                    vertical-align: inherit;
                }
            }

            .c-form__form-table__item__input {
                display: block;
                width: 100%;
                padding: 10px 15px 20px 15px;

                button {
                    margin-right: 0;
                    margin-bottom: 10px;
                    display: block;
                }
            }
        }

        .c-form__privacy {
            margin: 0 0 20px 0;
            text-align: left;
        }

        .c-form__confirmation {
            max-width: 400px;
        }
    }
}